<script lang="ts" setup>
import EmojiPicker from 'vue3-emoji-picker';
import 'vue3-emoji-picker/css';
import type { HorizonComponentProps } from '../../types';

const props = defineProps<HorizonComponentProps>();

defineEmits(['onSelectEmoji']);
</script>

<template>
  <ClientOnly>
    <EmojiPicker
      style="--header-text-color: black; --text-color: black"
      :native="true"
      :disable-skin-tones="true"
      :data-testid="props.testId + '/EmojiPicker'"
      @select="$emit('onSelectEmoji', $event)"
    />
  </ClientOnly>
</template>

